var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Saaja, maksuviite, summa...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.otherPayments,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.paymentDate))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v(_vm._s(_vm.formatCurrency(item.amount)))])]}},{key:"item.joinedTo",fn:function(ref){
var item = ref.item;
return [(item.joinedTo)?_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"primary--text",staticStyle:{"cursor":"pointer"}},'div',attrs,false),on),[_c('span',[_vm._v(" # "+_vm._s(item.joinedTo.paymentNumber)+" ")])])]}}],null,true)},[_c('h4',{staticClass:"mb-1"},[_vm._v("Kohdistetut maksut")]),_c('p',{staticStyle:{"margin-bottom":"6px","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.formatDateMonthName(item.joinedTo.targetMonth))+" ")]),_c('p',[_vm._v(" "+_vm._s(item.joinedTo.paymentType == "payment" ? "Maksu" : "Kulu")+": # "+_vm._s(item.joinedTo.paymentNumber)+" ")])]):_c('span')]}},{key:"item.paymentReference",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.paymentReference))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('outgoingPayment', 'edit'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){_vm.$store.state.outgoingpayment.currentOtherPayment = Object.assign({}, item);
            _vm.editPaymentDialog = true;}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isAuthorized('outgoingPayment', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei kohdistumattomia suorituksia")])]},proxy:true}],null,true)}),_c('edit-other-payment',{model:{value:(_vm.editPaymentDialog),callback:function ($$v) {_vm.editPaymentDialog=$$v},expression:"editPaymentDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }