<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title>Muokkaa suoritusta {{ currentOtherPayment.name }}</v-card-title>

      <v-card-text v-show="!currentOtherPayment.joinedTo">
        <v-form ref="form">
          <h3 class="mb-1">Kohdista lähtevään maksuun</h3>
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-autocomplete
                v-model="outgoingPayment"
                :items="activePayments"
                :item-text="fixedName"
                item-value="_id"
                label="Lähtevä maksu"
                no-data-text="Maksun numero, vuokrakohteen osoite tai viite"
                outlined
                small-chips
                return-object
                deletable-chips
                hide-details
                :rules="required"
                :search-input.sync="searchInput"
                @change="searchInput = ''"
                @input.native="getOutgoingPayment"
              >
              </v-autocomplete>
              <small>K = Kulu, M = Maksu, V = Viite</small>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text v-if="currentOtherPayment.joinedTo">
        <h3>
          Suoritus on jo kohdistettu maksuun # {{ currentOtherPayment.joinedTo.paymentNumber }}
        </h3>
        <p>Poista suoritus tarvittaessa kohdistetun maksun kautta.</p>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!currentOtherPayment.joinedTo" color="info" @click="join()">Tallenna</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState } from "vuex";
import { debounce } from "../../utils/helpers";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      outgoingInput: "",
      outgoingPayment: null,
      searchInput: "",
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("outgoingpayment", ["activePayments", "currentOtherPayment"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },

    outgoingInput: debounce(function (newVal) {
      this.searchPayments(`/api/v1/outgoing-payment/search-payment?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("outgoingpayment", ["searchPayments", "joinOtherPayment"]),

    getOutgoingPayment(event) {
      this.outgoingInput = event.target.value;
    },

    fixedName(item) {
      if (item.apartment) {
        const number = item.apartment.apartmentNumber ? `, ${item.apartment.apartmentNumber}` : "";
        const paymentType = item.paymentType === "payment" ? "M" : "K";
        const reference = item.paymentReference ? item.paymentReference : "";
        return (
          `${paymentType}=` +
          `${item.paymentNumber}, (${item.apartment.address}${number}) V=${reference}`
        );
      } else {
        return "";
      }
    },

    async join() {
      if (this.$refs.form.validate()) {
        await this.joinOtherPayment({
          ogpId: this.outgoingPayment._id,
          otherPayment: this.currentOtherPayment,
        });
        this.dialog = false;
      }
    },
  },
};
</script>

<style scoped>
.v-card__title .headline {
  font-weight: 300;
}
</style>
