<template>
  <v-card>
    <v-container>
      <!-- Search by dates -->
      <search-by-dates
        ref="searchByDates"
        :showSearchBy="false"
        :checkStartDate="allowedStartDatesFunction"
        :checkEndDate="allowedEndDatesFunction"
        :startDateRequired="true"
        :endDateRequired="true"
      ></search-by-dates>

      <v-btn color="primary mt-1" @click="getDataFromApi">Hae</v-btn>

      <!-- <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            label="Maksun nro, vuokrakohde, toimeksiantaja, viite..."
            single-line
            hide-details
            solo
          ></v-text-field>
        </v-col>
      </v-row> -->
    </v-container>

    <v-data-table
      :headers="headers"
      :items="checkList"
      :footer-props="globalValues.footerProps"
      :items-per-page="getDefaultTableRows()"
      :loading="loading"
    >
      <!-- Apartment -->
      <template #[`item.apartment`]="{ item }">
        <router-link style="text-decoration: none" :to="`/apartment/overview/${item.apartmentId}`">
          <span>
            {{ item.address }}<span v-if="item.apartmentNumber">, {{ item.apartmentNumber }}</span>
          </span>
        </router-link>
      </template>

      <!-- owners -->
      <template #[`item.owners`]="{ item }">
        <span class="error--text" v-if="item.owners.length == 0">Ei omistajia</span>
        <div v-else v-for="(owner, idx) in item.owners" :key="'a' + idx">
          <router-link
            v-if="owner.onModel == 'Partner'"
            style="text-decoration: none"
            :to="`/partner/overview/${owner.ownerId}`"
          >
            <span>{{ owner.name }}</span>
          </router-link>
          <span v-else>{{ owner.name }}</span>
        </div>
      </template>

      <!-- contracts -->
      <template #[`item.contracts`]="{ item }">
        <span class="error--text" v-if="item.contracts.length == 0">Ei omistajia</span>
        <div v-else v-for="(c, idx) in item.contracts" :key="'a' + idx">
          <p>{{ c.tenant }} (sop. {{ c.contractNumber }})</p>
          <p>
            {{ formatDate(c.startDate) }}
            <span v-if="c.revokedDate || c.endDate"
              >- {{ c.revokedDate ? formatDate(c.revokedDate) : formatDate(c.endDate) }}</span
            >
          </p>
        </div>
      </template>

      <!-- total invoicing including taxes -->
      <template #[`item.totalAmountInvoices`]="{ item }">
        <span>{{ formatCurrency(item.totalAmountInvoices) }}</span>
      </template>

      <!-- interests -->
      <template #[`item.interests`]="{ item }">
        <span>{{ formatCurrency(item.totalAmountInvoices - item.productsAmount) }}</span>
      </template>

      <!-- all invoice products, invoices minus refundinvoices -->
      <template #[`item.tenantGoal`]="{ item }">
        <span class="font-weight-bold">{{ formatCurrency(item.productsAmount) }}</span>
      </template>

      <!-- openAmountInvoices-->
      <template #[`item.tenantState`]="{ item }">
        <span
          class="font-weight-bold"
          :class="item.openAmountInvoices == 0 ? 'success--text' : 'error--text'"
          >{{ formatCurrency(item.openAmountInvoices) }}</span
        >
      </template>

      <!-- payments invoices - payments refundinvoices -->
      <template #[`item.ownerGoal`]="{ item }">
        <span class="font-weight-bold">{{ formatCurrency(calcOwnerGoal(item)) }}</span>
      </template>

      <!-- paid to owners total -->
      <template #[`item.paidToOwner`]="{ item }">
        <span>{{ formatCurrency(Math.abs(item.paymentsTotal)) }}</span>
      </template>

      <!-- owner state-->
      <template #[`item.ownerState`]="{ item }">
        <span
          class="font-weight-bold"
          :class="
            parseFloat(calcOwnerState(item).toFixed(1)) === 0 ? 'success--text' : 'error--text'
          "
          ><span>{{
            parseFloat(calcOwnerState(item).toFixed(1)) < 0
              ? "Laskutetaan"
              : parseFloat(calcOwnerState(item).toFixed(1)) > 0
              ? "Maksetaan"
              : ""
          }}</span>
          {{ formatCurrency(Math.abs(calcOwnerState(item))) }}</span
        >
      </template>

      <!-- receipts total-->
      <template #[`item.receiptsTotal`]="{ item }">
        <span>{{ formatCurrency(Math.abs(item.receiptsTotal)) }}</span>
      </template>

      <!-- Total receipts amount
      <template #[`item.receiptsAmount`]="{ item }">
        <v-tooltip v-if="item.receipts.length > 0" top max-width="350">
          <template #activator="{ on, attrs }">
            <div class="primary--text" style="cursor: pointer" v-bind="attrs" v-on="on">
              <span v-if="!includesInActiveReceipts(item)">Erittely</span>
              <span v-else>
                <v-icon color="warning" small>mdi-alert-circle</v-icon>
              </span>
            </div>
          </template>

          <h4 class="mb-1">Tuotteet</h4>
          <div v-for="(receipt, index) in item.receipts" :key="index + 'e'">
            <strong>{{ receipt.name }}</strong>
            <div v-for="(prod, index) in receipt.products" :key="index + 'f'">
              <span :class="{ 'joined-payment': !receipt.active }"
                >{{ prod.name }}: {{ formatCurrency(prod.count * prod.amount) }}</span
              >
            </div>
          </div>
        </v-tooltip>

        <span v-if="item.receipts.length > 0" class="error--text font-weight-bold">{{
          formatCurrency(item.receiptsAmount)
        }}</span>
      </template>

       Actions
      <template #[`item.actions`]="{ item }">
        <div class="action-grid-container">
          <v-icon
            v-if="isAuthorized('outgoingPayment', 'edit') && tab == 0"
            title="Muokkaa"
            small
            @click="
              $store.state.outgoingpayment.currentPayment = { ...item };
              editPaymentDialog = true;
            "
            >mdi-pencil</v-icon
          >
        </div>
      </template> -->

      <template #no-data>
        <h2>Ei sopimuksia valitulla aikavälillä</h2>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";
import SearchByDates from "../Invoice/SearchByDates.vue";
import moment from "moment";
import globalValues from "@/configs/globalValues";

export default {
  title: "Lähtevät maksut",

  components: {
    SearchByDates,
  },

  props: ["tab"],

  mixins: [mixins],

  data: () => ({
    loading: false,
    search: "",
    headers: [
      { text: "Osoite", value: "apartment" },
      { text: "Sopimukset", value: "contracts" },
      { text: "Toimeksiantaja", value: "owners" },
      { text: "Saatavat", value: "totalAmountInvoices" },
      { text: "Korot", value: "interests" },
      { text: "Tavoite Vu", value: "tenantGoal" },
      { text: "Tilanne Vu", value: "tenantState" },
      { text: "Tavoite Va", value: "ownerGoal" },
      { text: "Kulut", value: "receiptsTotal" },
      { text: "Maksetut Va", value: "paidToOwner" },
      { text: "Tilanne Va", value: "ownerState" },
      //   {
      //     text: "Toiminnot",
      //     value: "actions",
      //     sortable: false,
      //     show: [0, 1],
      //   },
    ],
    globalValues,
    // options: {},
  }),

  computed: {
    ...mapState("outgoingpayment", ["checkList", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
    ...mapState("invoice", ["searchByDates"]),
  },

  watch: {
    // options: {
    //   handler() {
    //     if (this.getPreviousPage) {
    //       if (this.mainSearchValue != "") this.search = this.mainSearchValue;
    //       this.options.page = this.dataTablePage;
    //       this.options.itemsPerPage = this.itemsPerPage;
    //     }
    //     this.saveTableOptions({ ...this.options });
    //   },
    //   deep: true,
    // },
    // totalLength() {
    //   this.options.page = 1;
    // },
  },

  methods: {
    ...mapActions("outgoingpayment", ["getCheckList"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      if (this.$refs.searchByDates.validate()) {
        this.loading = true;
        this.saveMainSearchTerm(this.search);

        const dates = {
          startDate: this.searchByDates.startDate,
          endDate: this.searchByDates.endDate,
        };

        await this.getCheckList(dates);
        this.loading = false;
      }
    },

    calcOwnerGoal(item) {
      return item.paymentInvoiceProductSum + item.paymentRefundInvoiceProductSum;
    },

    calcOwnerState(item) {
      return this.calcOwnerGoal(item) + item.receiptsTotal + item.paymentsTotal;
    },

    includesInActiveReceipts(item) {
      return item.receipts.filter((el) => el.active == false).length > 0;
    },

    allowedStartDatesFunction(val) {
      const today = moment().format("YYYY-MM-DD");
      if (moment(today).subtract(2, "years").isSameOrBefore(val)) {
        return true;
      } else return false;
    },

    allowedEndDatesFunction(val) {
      const today = moment().format("YYYY-MM-DD");
      if (moment(today).subtract(2, "years").isSameOrBefore(val)) {
        return true;
      } else return false;
    },
  },
};
</script>

<style scoped></style>
